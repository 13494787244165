.input {
  width: 100%;
}

.button {
  margin-top: 10px;
  width: 100%;
}

.addButton {
  max-width: 200px;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.bannerCardsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
