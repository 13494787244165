@value desktop-s, secondary-font, main-dark from "@/styles/variables.module.css";

.biographyContainer {
  border-left: solid 1px main-dark;
  padding-left: 20px;
}

.biography {
  display: -webkit-box;
  transition: all 0.2s ease-in-out;
  margin: 0;
  height: 100%;
  max-height: 250px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
  font-family: secondary-font;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 9;
  overflow: hidden;
  text-overflow: ellipsis;

  @media desktop-s {
    -webkit-line-clamp: 16;
    max-height: 450px;
  }
}

.biographyOpen {
  -webkit-line-clamp: unset;
  max-height: none;
}

.biographyWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.buttonContainer {
  margin: 0 auto;
}
