@value padding: 12px;

.categoryContainer {
  position: absolute;
  top: padding;
  left: padding;
}

.favoriteContainer {
  position: absolute;
  top: padding;
  right: padding;
}

.contentContainer {
  display: flex;
  position: absolute;
  bottom: padding;
  left: padding;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding-right: padding;
  width: 100%;
  max-width: calc(100% - padding);
}

.contentContainer > :first-child {
  margin: 0 0 10px 0;
  width: 100%;
}

.linksContainer {
  display: flex;
  column-gap: 12px;
  flex-direction: row;
  width: 100%;
}

.linksContainer > * {
  flex: 1;
}

.title {
  color: #ffffff;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  font-family: Oxygen;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
}

.categoryButton {
  cursor: pointer;
  border: none;
  background: none;
}

.bottomWhiteBox {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  height: 50px;
  font-weight: 700;
  font-size: 12px;
  font-family: 'Oxygen';
  letter-spacing: 1.6px;
  text-align: center;
  text-transform: uppercase;
}
