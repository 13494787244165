@value max-medium from "@/styles/variables.module.css";

.recycleImagesCarouselContainer {
  width: 100%;
  max-width: 450px;
}

@media max-medium {
  .recycleImagesCarouselContainer {
    margin-top: 15px;
    max-width: 100%;
  }
}
