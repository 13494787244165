.profileCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  width: 100%;
}

.profileInfo {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.profileAvatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  overflow: hidden;
}

.profileInitial {
  font-weight: bold;
  font-size: 1.125rem;
}

.profileName {
  font-weight: 600;
  font-size: 1.125rem;
}

.profileUpdated {
  font-size: 0.875rem;
  white-space: nowrap;
}

.profileActions {
  display: flex;
  gap: 0.5rem;
}

.button {
  max-height: 30px;
}
