@value max-small, max-medium, max-large, max-xlarge, min-large from "@/styles/variables.module.css";

.heroContainer {
  margin: 0;
}

.heroContent {
  display: flex;
  row-gap: 24px;
  flex-direction: column;
  margin: 0;
  background-color: #fff;
  padding: 50px 140px 50px 70px;

  @media min-large {
    border: 9px solid #dabbb7;
  }

  @media max-large {
    padding: 40px 50px;
  }

  @media max-medium {
    row-gap: 12px;
    padding: 0;
  }
}

.heroHeader {
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 69px;
  font-family: 'DMSerifDisplay';

  @media max-large {
    font-size: 30px;
    line-height: 36px;
  }

  @media max-medium {
    font-size: 24px;
    line-height: 26px;
  }
}

.heroSummary {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  font-family: Oxygen;
  text-align: left;
}

.heroLocation {
  display: flex;
  column-gap: 4px;
  flex-wrap: wrap;
  align-items: center;
}

.heroLocationIcon {
  margin-right: 8px;
}

.heroLocationLabel {
  margin-bottom: 2px;
  color: #696969;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  @media max-small {
    margin-bottom: -2px;
  }
}

.heroButtons {
  display: flex;
  column-gap: 20px;
  margin-top: 20px;
}

.heroButtons > * {
  @media max-medium {
    flex: 1;
  }
}
