@value desktop-s, tablet from "@/styles/variables.module.css";

.grid {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media tablet {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 28px;
  }

  @media desktop-s {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 48px;
  }
}
