@value padding: 12px;

.contentContainer {
  display: flex;
  position: absolute;
  bottom: padding;
  left: padding;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding-right: padding;
  width: 100%;
  max-width: calc(100% - padding);
}

.favoriteContainer {
  position: absolute;
  top: padding;
  right: padding;
}

.title {
  margin: 0;
  background-color: #fff;
  padding: 4px 8px;
  color: #000;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  font-family: Oxygen;
  letter-spacing: 0em;
  text-align: left;
  text-overflow: ellipsis;
  text-transform: uppercase;
}
