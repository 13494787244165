@value max-small, max-medium, max-large, max-xlarge, modalZIndex from "@/styles/variables.module.css";

.wrapper {
  position: relative;
  flex: 1;
  height: 50px;
}

.button {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: 1px solid #d9d9d9;
  background-color: #ffffff;
  padding: 0px 16px;
  width: 100%;
  height: 50px;
  color: #000;
}

.menuItems {
  composes: hideScrollbar from '@/styles/global.module.css';
  display: flex;
  position: absolute;
  top: 100%;
  left: 0;
  flex-direction: column;
  align-items: flex-start;
  z-index: modalZIndex;
  margin: 0;
  box-shadow:
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 0;
  padding: 12px 16px;
  width: 100%;
  max-height: 475px;
  overflow-y: auto;
  color: #000000;
  list-style: none;

  @media max-small {
    max-height: 400px;
  }

  @media max-medium {
    max-height: 450px;
  }
}

.menuItems > div {
  width: 100%;
}

.tabList {
  border-bottom: 1px solid #d9d9d9;
  padding: 0px 12px;
  width: 100%;
}

.tabButton {
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 12px 0px;
  color: #000;
  font-weight: bold;
  font-size: 14px;
  font-family: 'Oxygen';
}

.tabButtonActive {
  border-bottom: 2px solid #000000;
}

.secondTabButtton {
  margin-left: 16px;
}

.tabGroup {
  display: flex;
  align-items: center;
}

.optionsContainer {
  composes: hideScrollbar from '@/styles/global.module.css';
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 12px;
  max-height: 475px;
  overflow-y: scroll;

  @media max-small {
    max-height: 400px;
  }

  @media max-medium {
    max-height: 450px;
  }

  @media max-xlarge {
    flex-direction: column;
  }
}

.optionWrapper {
  flex: 1;
  width: 100%;
}

.styledItem {
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 10px 10px;
  width: 100%;
  min-width: 160px;
  color: #000;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Oxygen';
  text-align: left;
}

.styledItem:hover {
  background-color: #f5f5f6;
}

.bold {
  font-weight: 700;
  text-transform: uppercase;
}
