@value max-small, max-medium, max-large, max-xlarge from "@/styles/variables.module.css";

.buttonContainer {
  display: flex;
  position: absolute;
  flex-direction: column;
  gap: 0.5rem;
  z-index: 99999;
  background-color: #ffffffba;
  padding-right: 24px;
  padding-left: 24px;
  width: 100%;
}

.container {
  position: relative;
}

.button {
  width: 100%;
}

.button:hover {
  background-color: #000000;
  color: #ffffff;
}
