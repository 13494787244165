@value max-medium from "@/styles/variables.module.css";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media max-medium {
    flex-direction: column;
  }
}

.mainFiltersWrapper {
  display: flex;
  gap: 12px;

  @media max-medium {
    flex-direction: column;
  }
}

.mainFiltersWrapper > *:first-child {
  flex: 2;
  height: 50px;
}

.checkboxWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 12px;
}

.additionalFiltersWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  background-color: #f5f5f5;
  padding: 12px;

  @media max-medium {
    grid-template-columns: 1fr;
  }
}

.linksWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 12px;

  @media max-medium {
    flex-direction: column;
  }
}
