@value desktop-s, tablet, main-dark, main-font, light-peach, main-font, secondary-font from "@/styles/variables.module.css";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 0 auto;
  padding: 32px 16px 24px;
  max-width: 886px;

  @media tablet {
    padding: 32px 16px;
  }

  @media desktop-s {
    padding: 32px 16px 24px 16px;
  }
}

.nameTitle {
  margin: 0;
  color: main-dark;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  font-family: secondary-font;
  text-align: center;
  text-transform: uppercase;
}

.name {
  margin: 0;
  color: main-dark;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 44px;
  font-family: main-font;
  text-align: center;
}

.bio {
  display: -webkit-box;
  color: main-dark;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  font-family: secondary-font;
  text-align: center;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media tablet {
    -webkit-line-clamp: 2;
  }
}

.button {
  border-radius: 2px;
  padding: 10px 10px;
  width: 154px;
}

.image {
  border-radius: 2px;
}

.nameWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
