@value secondary-font, main-dark from "@/styles/variables.module.css";

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition: all 0.1s ease;
  cursor: pointer;
  margin: 28px auto;
  margin: 0;
  border: 0;
  border-radius: 2px;
  background-color: transparent;
  padding: 20px 16px;
  width: 100%;
  height: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: secondary-font;
}

.button:focus-visible {
  outline: none;
}

.button.primary {
  border: 1px solid main-dark;
  background-color: #fff;
  color: main-dark;
}

.button.primary:hover {
  border: 1px solid #fff;
  background-color: main-dark;
  color: #fff;
}

.button.primary:hover svg path {
  fill: #fff;
}

.button.secondary {
  border: 1px solid #fff;
  background-color: main-dark;
  color: #fff;
}

.button.secondary:hover {
  border: 1px solid main-dark;
  background-color: #fff;
  color: main-dark;
}

.button.secondary:hover svg path {
  fill: main-dark;
}

.disabled {
  opacity: 0.2;
  pointer-events: none;
}
