.wrapper {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 2px dashed #ccc;
  border-radius: 4px;
  background-color: #f5f5f5;
  width: 150px;
  height: 150px;
  overflow: hidden;
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.spinner {
  position: absolute;
  animation: spin 1s linear infinite;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

.altInput {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 12px;
  width: 200px;
  font-size: 14px;
}

.altInput:focus {
  outline: none;
  border-color: #3498db;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.label {
  color: #333;
  font-size: 14px;
}
