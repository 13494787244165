.container {
  display: flex;
  row-gap: 12px;
  flex-direction: column;
}

.linksWrapper {
  display: flex;
  column-gap: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 24px 0;
}

.button {
  display: flex;
  column-gap: 12px;
  flex-direction: row;
  align-items: center;
}

.icon {
  font-size: 12px;
}

.counter {
  color: #999999;
  font-size: 16px;
}
