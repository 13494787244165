@value main-font, desktop-s  from '@/styles/variables.module.css';

.title {
  margin: 8px 0 24px 0;
  font-weight: 400;
  font-size: 28px;
  line-height: 44px;
  font-family: main-font;

  @media desktop-s {
    margin: 32px 0 48px 0;
    font-size: 36px;
    line-height: 48px;
    letter-spacing: 1%;
  }
}
