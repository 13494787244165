.label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  padding: 18px 20px;
  color: #000000;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
}

.contentContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 12px;
  width: 100%;
}
