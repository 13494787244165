@value main-font from "@/styles/variables.module.css";

.heading {
  width: 100%;
  color: #000;
  font-weight: 400;
  font-size: 28px;
  line-height: 30px;
  font-family: main-font;
}
